<template>
  <div className='crm-iframe-wrap'>
      <div class="btn-back" @click="handleJump">返回首页</div>
      <!-- <iframe
        :src="url"
        frameBorder={0}
        :style="{
          display: 'block',
          width: '100%',
          height: 'calc(100vh)',
        }"
      /> -->
    </div>
</template>
<script>
export default {
  name: 'CrmIframe',
  data() {
    return {
      url: 'https://marketplace.huaweicloud.com/hidden/contents/e696e332-5244-4240-bc7f-b5e2c0c1733d#productid=OFFI1028603974544052224',
    }
  },
  methods: {
    handleJump() {
      if (typeof wx !== 'undefined' && wx.miniProgram) {
        console.log(wx, '进来了');
          // 调用小程序的页面跳转API
          wx.miniProgram.navigateTo({
              url: '/pages/index/index' // 小程序内页面路径
          });
      } else {
        // 非微信环境，可以给出提示或者跳转到其他H5页面
        console.log('请在微信环境中打开H5页面！');
      }
    },
  },

}
</script>
<style lang="less">
  .btn-back{
    width:100%;
    padding: 30px 0;
    text-align: center;
  }
</style>