<template>
  <div>
    <Navgation />
    <div class="container">
      <Jumbotron :url="isMobile ? 'news-m-banner' : 'dynamic-news'" />
      <div class="news-list">
        <div class="item" v-for="(item, index) in newsList" :key="index">
          <div class="left">
            <div
              class="title"
              @click="
                $router.push({
                  path: '/news-detail',
                  query: { article: index },
                })
              "
            >
              {{ item.title }}
            </div>
            <div class="content">{{ item.content }}</div>
            <div class="info">
              <span class="time"
                >发布时间：<span class="font">{{ item.time }}</span></span
              >
              <span class="read">{{ item.read }}阅读</span>
            </div>
          </div>
          <div class="right">
            <img
              :src="require(`@/assets/images/news-${item.key}.jpg`)"
              class="image"
            />
          </div>
        </div>
      </div>
      <!-- <div class="view-more">
        <span class="button">查看更多</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Jumbotron } from '@/base'
import checkIsMobile from '@/mixin/checkIsMobile'
import { newsList } from '@/config'
import Navgation from '@/layouts/components/Navgation'
import { pageSeoInfo } from '@/config'
export default {
  name: 'News',
  mixins: [checkIsMobile],
  filters: {},
  components: { Jumbotron, Navgation },
  props: {},
  data() {
    return {
      newsList,
    }
  },
  metaInfo: {
    title: pageSeoInfo.news.title,
    meta: [{
      name: 'keywords',
      content: pageSeoInfo.news.keywords
    },
    {
      name: 'description',
      content: pageSeoInfo.news.description
    }]
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {},
}
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .container {
    margin-top: @navHeight;
    .news-list {
      width: @panelWidth;
      margin: 0 auto;
      margin-top: 75px;
      .item {
        display: flex;
        border-bottom: 1px solid #e3e8ee;
        margin-bottom: 26px;
        .left {
          width: calc(100% - 128px);
          padding-right: 83px;
          .title {
            font-size: 20px;
            font-family: AlibabaPuHuiTiM;
            color: #000000;
            line-height: 27px;
            cursor: pointer;
            margin-bottom: 16px;
          }
          :hover {
            color: #2761ff;
          }
          .content {
            font-size: 14px;
            font-family: AlibabaPuHuiTiR;
            color: #86909c;
            line-height: 24px;
            margin-bottom: 16px;
          }
          .info {
            display: flex;
            margin-bottom: 36px;
            .time {
              font-size: 14px;
              font-family: AlibabaPuHuiTiR;
              color: #86909c;
              line-height: 24px;
              .font {
                color: #000000;
              }
            }
            .read {
              padding-left: 36px;
              font-size: 14px;
              font-family: AlibabaPuHuiTiR;
              color: #86909c;
              line-height: 24px;
            }
          }
        }
        .right {
          width: auto;
          display: flex;
          align-items: center;
          .image {
            width: 170px;
            height: 127px;
          }
        }
      }
    }
    .view-more {
      margin: 4px 0 75px 0;
      text-align: center;
      .button {
        font-size: 14px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        line-height: 20px;
        cursor: pointer;
      }
      :hover {
        color: #2761ff;
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .news-list {
      padding: 43px 40px 0 40px;
      .item {
        display: flex;
        border-bottom: 1px solid #e3e8ee;
        margin-bottom: 44px;
        justify-content: space-between;
        .left {
          .title {
            font-size: 28px;
            font-weight: bold;
            color: #000000;
            line-height: 38px;
            cursor: pointer;
            margin-bottom: 12px;
          }
          .content {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 457px;
            font-size: 20px;
            color: #86909c;
            line-height: 32px;
            margin-bottom: 29px;
          }
          .info {
            display: flex;
            margin-bottom: 54px;
            .time {
              font-size: 20px;
              color: #86909c;
              line-height: 24px;
              .font {
                color: #000000;
                font-weight: 500;
              }
            }
            .read {
              padding-left: 20px;
              font-size: 20px;
              color: #86909c;
              line-height: 24px;
            }
          }
        }
        .right {
          width: auto;
          display: flex;
          align-items: center;
          .image {
            width: 180px;
            height: 134px;
          }
        }
      }
    }
    .view-more {
      margin: 40px 0 109px 0;
      text-align: center;
      .button {
        font-size: 20px;
        color: #000000;
        line-height: 15px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
