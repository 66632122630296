<template>
  <section class="section" style="position: relative">
    <div class="section-box" style="position: relative">
      <div class="banner-wrapper">
        <div class="yszc-zs"></div>
        <Navgation :isPageHome="true" />

        <div class="option-panel">
          <div class="banner-bg"></div>
          <div class="option-panel-box">
            <div class="option-title">{{ title }}</div>
            <div :class="{ 'option-cell': true }">
              <div class="name-cell">
                <div
                  :class="{
                    'name-txt': true,
                    name2: index === 1,
                    active: currentIndex === item.value ? true : false,
                  }"
                  v-for="(item, index) in optionsName"
                  :key="item.value"
                  @click="handleChangeName(item, index)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="form-cell">
                <div class="qm-cell" v-if="currentIndex === 1">
                  <div class="form-item form-ml">
                    <el-cascader
                      v-model="form.address"
                      placeholder="请选择所在地区，如“杭州”"
                      :options="optionsTree"
                      :props="cascaderProps"
                      @change="handleChangeTree"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                  <div class="form-item form-ml">
                    <el-input
                      v-model="form.words"
                      maxlength="1"
                      placeholder="请输入喜好的一个字"
                    ></el-input>
                  </div>
                  <div class="form-item form-ml">
                    <el-select
                      v-model="form.type"
                      placeholder="请选择名称字数"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in numList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-item form-ml form-mr">
                    <el-cascader
                      v-model="form.industry"
                      placeholder="请选择行业，如“网络科技”"
                      :options="optionsIndustry"
                      :props="cascaderProps2"
                      @change="handleChangeIndustry"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                </div>
                <div class="cm-cell" v-else-if="currentIndex === 2">
                  <div class="form-item form-ml form-mr">
                    <el-input
                      v-model="form.companyName"
                      placeholder="请输入公司全称"
                    ></el-input>
                  </div>
                </div>
                <div class="hm-cell" v-else>
                  <div class="form-item">
                    <el-cascader
                      v-model="form.address"
                      placeholder="请选择所在地区，如“杭州”"
                      :options="optionsTree"
                      :props="cascaderProps"
                      @change="handleChangeTree"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                  <div class="form-item form-ml">
                    <el-input
                      v-model="form.hotword"
                      placeholder="请输入名称，如“华为”"
                    ></el-input>
                  </div>
                  <div class="form-item form-ml form-mr">
                    <el-cascader
                      v-model="form.industry"
                      placeholder="请选择行业，如“网络科技”"
                      :options="optionsIndustry"
                      :props="cascaderProps2"
                      @change="handleChangeIndustry"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                  <div class="form-txt">有限公司</div>
                </div>
              </div>
            </div>

            <div class="submit-btn" @click="handleSubmit">{{ btnTxt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'option-cell2': true,
        fixed: true,
        'move-top': isShowXD,
        'move-hide': isShowXD2,
      }"
      v-show="isShowAdsorption"
    >
      <div class="name-cell">
        <div
          :class="{
            'name-txt': true,
            name2: index === 1,
            active: currentIndex === item.value ? true : false,
          }"
          v-for="(item, index) in optionsName"
          :key="item.value"
          @click="handleChangeName(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="form-cell">
        <div class="qm-cell" v-if="currentIndex === 1">
          <div class="form-item form-ml">
            <el-cascader
              v-model="form.address"
              placeholder="请选择所在地区，如“杭州”"
              :options="optionsTree"
              :props="cascaderProps"
              @change="handleChangeTree"
            ></el-cascader>
          </div>
          <div class="form-item form-ml">
            <el-input
              v-model="form.words"
              maxlength="1"
              placeholder="请输入喜好的一个字"
            ></el-input>
          </div>
          <div class="form-item form-ml">
            <el-select v-model="form.type" placeholder="请选择名称字数">
              <el-option
                v-for="item in numList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form-item form-ml form-mr">
            <el-cascader
              v-model="form.industry"
              placeholder="请选择行业，如“网络科技”"
              :options="optionsIndustry"
              :props="cascaderProps2"
              @change="handleChangeIndustry"
            ></el-cascader>
          </div>
          <div class="submit-btn" @click="handleSubmit">{{ btnTxt }}</div>
        </div>
        <div class="cm-cell" v-else-if="currentIndex === 2">
          <div class="form-item form-ml form-mr">
            <el-input
              v-model="form.companyName"
              placeholder="请输入公司全称"
            ></el-input>
          </div>
          <div class="submit-btn" @click="handleSubmit">{{ btnTxt }}</div>
        </div>
        <div class="hm-cell" v-else>
          <div class="form-item form-ml">
            <el-cascader
              v-model="form.address"
              placeholder="请选择所在地区，如“杭州”"
              :options="optionsTree"
              :props="cascaderProps"
              @change="handleChangeTree"
            ></el-cascader>
          </div>
          <div class="form-item form-ml">
            <el-input
              v-model="form.hotword"
              placeholder="请输入名称，如“华为”"
            ></el-input>
          </div>
          <div class="form-item form-ml form-mr">
            <el-cascader
              v-model="form.industry"
              placeholder="请选择行业，如“网络科技”"
              :options="optionsIndustry"
              :props="cascaderProps2"
              @change="handleChangeIndustry"
            ></el-cascader>
          </div>
          <div class="form-txt">有限公司</div>
          <div class="submit-btn" @click="handleSubmit">{{ btnTxt }}</div>
        </div>
      </div>
    </div>
    <!-- 登录二维码 -->
    <QrCodeModal
      :QrCodeDialogVisible="qrVisible"
      @closeQr="closeQr"
      :qrUrl="qrUrl"
      v-if="qrVisible"
    >
    </QrCodeModal>
    <!-- 起名结果 -->
    <TheNameResultModal
      :theNameResultVisible="theNameResultVisible"
      @closeTheNameResult="closeTheNameResult"
      @updateQm="updateQm"
      :nameList="qmResult"
    >
    </TheNameResultModal>
    <!-- 核名结果 -->
    <NameCheckResult
      :nameCheckResultVisible="nameCheckResultVisible"
      @closeNameCheckResult="closeNameCheckResult"
      :hmParams="hmParams"
      :hmResult="hmResult"
    >
    </NameCheckResult>
    <!-- 测名结果 -->
    <CmResult
      :cmResultVisible="cmResultVisible"
      @closeCmResult="closeCmResult"
      :cmResult="cmResult"
      :cmParams="cmParams"
    >
    </CmResult>
    <div class="loading-box" v-if="showLoading">
      <!-- <img src="@/assets/images/home/loading.webp" alt="" /> -->
      <img src="@/assets/images/home/loading.png" alt="" />
      <div>正在核名,请勿离开…</div>
    </div>
  </section>
</template>

<script>
import { syBannerInfo, qmInfo, cmInfo, hmInfo } from "@/config";
import Modal from "@/components/modal";
import Navgation from "@/layouts/components/Navgation";
import homeApi from "@/api/home.api.js";
import QrCodeModal from "@/views/homePopup/qrCode.vue";
import TheNameResultModal from "@/views/homePopup/theNameResult.vue";
import NameCheckResult from "@/views/homePopup/nameCheckResult.vue";
import CmResult from "@/views/homePopup/cmResult.vue";
import auth from "@/utils/auth.js";
import dayjs from "dayjs";

export default {
  name: "Banner1",
  computed: {},
  data() {
    return {
      visible: false,
      form: {
        address: "",
        words: "",
        type: "",
        industry: "",
        industryLabel: "",
        city: "",
        province: "",
        companyName: "",
        organizationForm: "有限公司",
      },
      companyNameReal: "",
      optionsTree: [],
      optionsIndustry: [],
      optionsName: [
        {
          name: "公司起名",
          value: 1,
        },
        {
          name: "公司测名",
          value: 2,
        },
        {
          name: "公司核名",
          value: 3,
        },
      ],
      numList: [
        {
          value: "2",
          label: "2",
          sort: 1,
        },
        {
          value: "3",
          label: "3",
          sort: 2,
        },
        {
          value: "4",
          label: "4",
          sort: 3,
        },
      ],
      cascaderProps: {
        label: "name",
        value: "name",
        children: "child",
      },
      cascaderProps2: {
        label: "name",
        value: "name",
        children: "children",
      },
      page: 0, // 起名第一批
      currentIndex: 1,
      title: "开公司，先得起个好名字",
      btnTxt: "免费起名",
      optionsInfo: [
        {
          title: "开公司，先得起个好名字",
          btn: "免费起名",
        },
        {
          title: "只需简单两步，为公司名称测试打分",
          btn: "测一测",
        },
        {
          title: "企业在线免费核名",
          btn: "提交核名",
        },
      ],
      isShowXD: false,
      isShowXD2: false,
      value: "",
      input: "",
      interval: null, //定时获取token
      qrVisible: false, //二维码弹窗开关
      theNameResultVisible: false, //起名结果弹窗开关
      nameCheckResultVisible: false, //核名结果弹窗开关
      cmResultVisible: false, //测名结果弹窗开关
      qrUrl: "", //二维码
      cmParams: {
        address: "", //地址
        city: "", //市
        companyName: "", //企业全称
        district: "", //区
        industry: "", //行业
        industryName: "", //行业名称
        province: "", //省
        words: "", //关键字
      },
      hmParams: {
        city: "", //城市
        cityId: "", //市代码
        company: "", //组织形式
        companyName: "", //企业全称
        companyType: "", //2 有限公司
        district: "", //区
        hotword: "", //字号
        industry: "", //行业类型
        industryCode: "", //行业编码
        industryId: "",
        mobile: "", //手机号
        openid: "", //公众号openId
        province: "", //省
        street: "", //街道
        tenant: "", //租户
      },
      qmResult: [],
      cmResult: {},
      hmResult: {},
      clickType: "", //mfqm免费签名  cyc测一测  tjhm提交核名
      showLoading: false,
      scrollTimer: null,
      oldScroll: 0,
      newScroll: 0,
      isShowAdsorption: false,
    };
  },
  inject: ["getPosition", "scrollTo"],
  methods: {
    // 埋点
    batchSave(type) {
      const refId = location.href.split("refId=")[1];
      const funcCodes = {
        起名: 101,
        测名: 102,
        核名: 103,
        工商注册: 104,
        打开代账机器人: 105,
      };
      if (location.href.includes("refId=")) {
        homeApi.batchSave([
          {
            apptype: 3,
            refId,
            triggertime: dayjs().format(),
            channel: "",
            companyId: "",
            companyName: "",
            createtime: "",
            datatype: "",
            deviceheight: "",
            devicetype: "",
            devicewidth: "",
            functioncode: funcCodes[type],
            functionname: type,
            id: "",
            imei: "",
            networktype: "",
            osname: "",
            pagecode: "",
            pageurl: "",
            phonenumber: "",
            platform: "",
            position: "",
            resource: "",
            sceneId: "",
            traceid: "",
            userId: "",
          },
        ]);
      }
    },
    onScroll() {
      const { scrollTop } = this.getPosition();
      // console.log(scrollTop)
      // if (scrollTop >= 600) {
      //   this.isShowXD2 = false
      //   this.isShowXD = true
      // } else if (this.isShowXD && scrollTop < 600 && scrollTop > 500) {
      //   this.isShowXD = false
      //   this.isShowXD2 = true
      // } else if (scrollTop <= 10) {
      //   this.isShowXD = false
      //   this.isShowXD2 = false
      // }
      if (scrollTop >= 600) {
        this.isShowXD2 = true;
        this.isShowXD = false;
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }
        this.oldScroll = scrollTop;
        this.scrollTimer = setTimeout(() => {
          this.handleScroll(scrollTop);
        }, 700);
      } else {
        this.oldScroll = 0;
        this.newScroll = 0;
        this.isShowXD2 = true;
        this.isShowXD = false;
      }
    },
    handleScroll(scrollTop) {
      if (!this.isShowAdsorption) {
        if (this.$route.query && this.$route.query.from === 'kbs') {
          this.isShowAdsorption = false;
        } else {
          this.isShowAdsorption = true;
        }
      }
      this.newScroll = scrollTop;
      if (this.newScroll == this.oldScroll) {
        this.isShowXD2 = false;
        this.isShowXD = true;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
        this.oldScroll = 0;
        this.newScroll = 0;
      }
    },
    handleChangeName(item, index) {
      this.currentIndex = item.value;
      this.title = this.optionsInfo[index].title;
      this.btnTxt = this.optionsInfo[index].btn;
    },
    updateQm() {
      this.page++;
      this.handleQm();
    },
    async handleSubmit() {
      if (this.currentIndex === 1) {
        this.clickType = "mfqm";
        // 这里是起名提交
        if (!this.form.address) {
          this.$message.error("请选择所在地区");
        } else if (!this.form.words) {
          this.$message.error("请输入喜好的一个字");
        } else if (!/^[\u4E00-\u9fa5]*$/g.test(this.form.words)) {
          this.$message.error("请输入一个中文字");
        } else if (!this.form.type) {
          this.$message.error("请选择名称字数");
        } else if (!this.form.industry) {
          this.$message.error("请选择行业");
        } else {
          if (!auth.getAccessToken()) {
            this.getQr();
            return false;
          }
          this.batchSave("起名");
          this.handleQm();
        }
      } else if (this.currentIndex === 2) {
        this.clickType = "cyc";
        // 这里是测名提交
        if (!this.form.companyName) {
          this.$message.error("请输入公司全称");
        } else if (!/^[\u4E00-\u9fa5]*$/g.test(this.form.companyName)) {
          this.$message.error("请输入中文");
        } else {
          if (!auth.getAccessToken()) {
            this.getQr();
            return false;
          }
          this.batchSave("测名");
          this.handleCm();
        }
      } else {
        this.clickType = "tjhm";
        // 这里是测名提交
        if (!this.form.address) {
          this.$message.error("请选择所在地区");
        } else if (!this.form.hotword) {
          this.$message.error("请输入名称");
        } else if (
          this.form.hotword.length < 2 ||
          this.form.hotword.length > 8
        ) {
          this.$message.error("请输入名称字数在2-8");
        } else if (!/^[\u4E00-\u9fa5]*$/g.test(this.form.hotword)) {
          this.$message.error("请输入中文名称");
        } else if (!this.form.industry) {
          this.$message.error("请选择行业");
        } else {
          if (!auth.getAccessToken()) {
            this.getQr();
            return false;
          }
          this.batchSave("核名");
          this.handleHm();
        }
      }
    },
    async handleQm() {
      const { city, industryLabel, type, words } = this.form;
      const sendData = {
        city,
        industry: industryLabel,
        page: this.page,
        type: +type,
        words,
      };
      const res = await homeApi.companyQm(sendData);
      this.scrollTo({ y: 0 });
      if (res.code === "0") {
        // 这里是起名返回结果
        this.qmResult = res.result;
        this.theNameResultVisible = true;
      }
    },
    async handleCm() {
      const sendData = {
        companyName: this.form.companyName,
      };
      this.cmParams = { ...sendData };
      const res = await homeApi.companyCm(sendData);
      if (res.code === "0") {
        this.scrollTo({ y: 0 });
        // 这里是测名返回结果
        this.cmResultVisible = true;
        this.cmResult = res.result;
      }
    },
    async handleHm() {
      let {
        city,
        hotword,
        industry,
        province,
        industryLabel,
        organizationForm,
      } = this.form;
      if (province.indexOf("市") !== -1) {
        city = province;
        province = province.split("市")[0];
      }
      this.companyNameReal =
        (this.form.city.indexOf("市") !== -1
          ? this.form.city.split("市")[0]
          : this.form.province.split("市")[0]) +
        this.form.hotword +
        this.form.industryLabel +
        this.form.organizationForm;
      const sendData = {
        city: city,
        company: organizationForm || "有限公司",
        companyName: this.companyNameReal,
        companyType: 2,
        hotword: hotword,
        industry: industryLabel,
        province,
      };
      this.hmParams = { ...sendData };
      this.showLoading = true;
      const res = await homeApi.checkName(sendData);
      if (res.code === "0") {
        // 这里是核名返回结果
        this.scrollTo({ y: 0 });
        this.hmResult = res.result;
        this.nameCheckResultVisible = true;
        this.showLoading = false;
      } else {
        this.showLoading = false;
      }
    },
    async getNodeTree() {
      const res = await homeApi.getAllNodeTree();
      if (res.code === "0") {
        this.optionsTree = res.result;
      }
    },
    closeQr() {
      this.qrVisible = false;
      window.clearInterval(this.interval);
      this.interval = null;
    },
    async closeTheNameResult(item) {
      this.theNameResultVisible = false;
      this.qmResult = [];
      this.page = 0;
      this.clickType = "";
      if (!auth.getAccessToken()) {
        this.getQr();
        return false;
      }
      if (item) {
        this.batchSave("测名");
        this.cmParams = {
          address: "",
          city: this.form.city,
          companyName: item.companyName,
          district: "",
          industry: this.form.industryLabel,
          industryName: "",
          province: "",
          words: item.words,
        };
        const res = await homeApi.companyCm(this.cmParams);
        if (res.code === "0") {
          // 这里是测名返回结果
          this.scrollTo({ y: 0 });
          this.cmResultVisible = true;
          this.cmResult = res.result;
        }
      }
    },
    closeNameCheckResult() {
      this.nameCheckResultVisible = false;
      this.hmResult = {};
      this.hmParams = {
        city: "", //城市
        cityId: "", //市代码
        company: "", //组织形式
        companyName: "", //企业全称
        companyType: "", //2 有限公司
        district: "", //区
        hotword: "", //字号
        industry: "", //行业类型
        industryCode: "", //行业编码
        industryId: "",
        mobile: "", //手机号
        openid: "", //公众号openId
        province: "", //省
        street: "", //街道
        tenant: "", //租户
      };
    },
    async closeCmResult(cmParams) {
      this.cmResultVisible = false;
      this.cmResult = {};
      this.cmParams = {
        address: "", //地址
        city: "", //市
        companyName: "", //企业全称
        district: "", //区
        industry: "", //行业
        industryName: "", //行业名称
        province: "", //省
        words: "", //关键字
      };
      this.clickType = "";
      if (!auth.getAccessToken()) {
        this.getQr();
        return false;
      }
      if (cmParams) {
        this.batchSave("核名");
        this.hmParams = {
          city: cmParams.city, //城市
          cityId: "", //市代码
          company: "有限公司", //组织形式
          companyName: cmParams.companyName, //企业全称
          companyType: "", //2 有限公司
          district: "", //区
          hotword: cmParams.words, //字号
          industry: cmParams.industry, //行业类型
          industryCode: "", //行业编码
          industryId: "",
          mobile: "", //手机号
          openid: "", //公众号openId
          province: cmParams.province, //省
          street: "", //街道
          tenant: "", //租户
        };
        this.showLoading = true;
        const res = await homeApi.checkName(this.hmParams);
        if (res.code === "0") {
          // 这里是核名返回结果
          this.scrollTo({ y: 0 });
          this.hmResult = res.result;
          this.nameCheckResultVisible = true;
          this.showLoading = false;
        } else {
          this.showLoading = false;
        }
      }
    },
    //获取二维码
    async getQr() {
      const res = await homeApi.getGwQrCode();
      if (!res.result) {
        this.$message.error("获取小程序二维码失败");
      }
      this.qrVisible = true;
      this.qrUrl = res.result.url;
      auth.saveLoginId(res.result.code);
      this.interval = setInterval(() => {
        this.getLoginInfo({ code: auth.getLoginId() });
      }, 5000);
    },
    //获取token
    async getLoginInfo(params) {
      const res = await homeApi.getLoginInfo(params);
      if (res.result.loginFlag) {
        auth.saveAccessToken(res.result.accessToken);
        document.cookie = "access_token=" + res.result.accessToken;
        window.clearInterval(this.interval);
        this.interval = null;
        this.closeQr();
        setTimeout(() => {
          if (this.clickType === "mfqm") {
            this.batchSave("起名");
            this.handleQm();
          } else if (this.clickType === "cyc") {
            this.batchSave("测名");
            this.handleCm();
          } else if (this.clickType === "tjhm") {
            this.batchSave("核名");
            this.handleHm();
          }
        }, 10);
      }
    },
    async getIndustry() {
      const res = await homeApi.getIndustry();
      if (res.code === "0") {
        this.optionsIndustry = this.renderTree(res.result);
      }
    },
    //渲染地区tree
    renderTree(treeData) {
      let newTreeData = [];
      for (let item of treeData) {
        newTreeData.push({
          code: item.code,
          name: item.name,
          children:
            item.children && item.children.length
              ? this.renderTree(item.children)
              : null,
        });
      }
      return newTreeData;
    },
    handleChangeTree(value) {
      console.log(value);
      this.form.city = value[1];
      this.form.province = value[0];
    },
    handleChangeIndustry(value) {
      this.form.industryLabel = value[1];
    },
  },
  mounted() {
    this.getNodeTree();
    this.getIndustry();
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
    window.removeEventListener("scroll", this.onScroll, true);
  },
  components: {
    Modal,
    Navgation,
    QrCodeModal,
    TheNameResultModal,
    NameCheckResult,
    CmResult,
  },
};
</script>

<style scoped lang="less">
.section {
  height: 800px;
}
.loading-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 3000;
  img {
    width: 200px;
  }
  div {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.banner-wrapper {
  width: 100%;
  height: 800px;
  overflow-x: hidden;
  background-color: #060b4c;
  // display: flex;
  // justify-content: c;
  // align-items: center;
  .yszc-zs {
    position: absolute;
    left: 0px;
    bottom: -476px;
    z-index: 12;
    width: 562px;
    height: 584px;
    background-image: url("../assets/images/cp/yszc-zs.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .option-panel {
    width: 1022px;
    margin: 0 auto;
    padding-top: 210px;
    position: relative;
    z-index: 3;
    .banner-bg {
      width: 878px;
      height: 585px;
      background-image: url("../assets/images/2.0/shy-banner.png");
      background-size: 100% 100%;
      position: absolute;
      left: 407px;
      top: 148px;
      z-index: 0;
    }
    .option-panel-box {
      position: relative;
      z-index: 1;
    }
    .option-title {
      width: 100%;
      text-align: center;
      font-size: 54px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 62px;
      background: linear-gradient(
        270deg,
        #51ffc4 0%,
        #24eaff 40%,
        #1464ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .option-cell {
      .name-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 22px;
        .name-txt {
          width: 146px;
          height: 51px;
          padding-top: 13px;
          box-sizing: border-box;
          flex-shrink: 1;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;
        }
        .name2 {
          margin: 0 10px;
        }
        .active {
          color: #060b4c;
          background-image: url("../assets/images/2.0/name-bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      &.prefixed {
        animation: option-top-hide 1s forwards ease-in-out;
      }
      &.fixed {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 13;
        width: 100%;
        height: 204px;
        background: rgba(6, 11, 76, 0.5);
        transform: translateY(-300px);
        .name-cell {
          padding-top: 40px;
          margin-bottom: 22px;
        }
        .form-cell {
          display: flex;
          justify-content: center;
          align-items: center;
          .qm-cell,
          .hm-cell {
            height: 68px;
            padding-left: 5px;
            .form-item {
              height: 42px;
              &.form-ml {
                margin-left: 10px;
              }
              &.form-mr {
                margin-right: 10px;
              }
            }
            .submit-btn {
              margin-right: 15px;
              cursor: pointer;
            }
          }
          .cm-cell {
            height: 68px;
            // padding-left: 5px;
            .form-item {
              height: 42px;
            }
            .submit-btn {
              margin-right: 15px;
            }
          }
          .hm-cell {
            width: auto;
            .form-txt {
              margin-right: 10px;
            }
            .submit-btn {
              margin-right: 15px;
              margin-left: 15px;
            }
          }
          .submit-btn {
            height: 42px;
          }
        }
      }
    }
    .submit-btn {
      width: 160px;
      height: 50px;
      margin: 0 auto;
      background: #2871ff;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.form-cell {
  margin-bottom: 36px;

  .qm-cell,
  .hm-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 870px;
    height: 80px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 17px;
    backdrop-filter: blur(25px);
    // padding: 0 3px;
    .form-item {
      width: 240px;
      height: 50px;
      // background: #FFFFFF;
      border-radius: 9px;
      flex-shrink: 1;

      &.form-ml {
        margin-left: 12px;
      }
      &.form-mr {
        margin-right: 15px;
      }
      .el-select,
      .el-cascader {
        width: 100%;
        height: 100%;
        /deep/ .el-input {
          height: 100%;
          // background: transparent;
          .el-input__inner {
            height: 100%;
            // background: transparent;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #060b4c;
            // border: 1px solid rgba(255, 255, 255, 0.25);
            border-radius: 9px;
            &::placeholder {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 0.4);
              // color: red;
              letter-spacing: -1px;
            }
          }
          .el-input__suffix {
            // color: #fff;
            color: rgba(26, 26, 26, 1);
            .el-input__icon {
              // color: #fff;
              color: rgba(26, 26, 26, 1);
            }
          }
        }
      }
      .el-input {
        width: 100%;
        height: 100%;
        /deep/ .el-input__inner {
          height: 100%;
          // background: transparent;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #060b4c;
          // border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 9px;
          &::placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(16, 42, 85, 0.4);
          }
        }
      }
    }
  }
  .hm-cell {
    width: 870px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;
    .form-txt {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .cm-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 614px;
    height: 80px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 17px;
    backdrop-filter: blur(25px);
    .form-item {
      width: 584px;
      height: 50px;
      // background: #FFFFFF;
      border-radius: 9px;
      &.form-ml {
        margin-left: 15px;
      }
      &.form-mr {
        margin-right: 15px;
      }
      .el-input {
        width: 100%;
        height: 100%;
        /deep/ .el-input__inner {
          height: 100%;
          // background: transparent;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #060b4c;
          // border: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 9px;
          &::placeholder {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(16, 42, 85, 0.4);
          }
        }
      }
    }
  }
}
.option-cell2 {
  .name-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
    .name-txt {
      width: 146px;
      height: 51px;
      padding-top: 13px;
      box-sizing: border-box;
      flex-shrink: 1;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      cursor: pointer;
    }
    .name2 {
      margin: 0 10px;
    }
    .active {
      color: #060b4c;
      background-image: url("../assets/images/2.0/name-bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &.prefixed {
    animation: option-top-hide 1s forwards ease-in-out;
  }
  &.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 13;
    width: 100%;
    height: 204px;
    background: rgba(6, 11, 76, 0.5);
    transform: translateY(-300px);
    .name-cell {
      padding-top: 40px;
      margin-bottom: 22px;
    }
    .form-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      .qm-cell,
      .hm-cell {
        height: 68px;
        padding-left: 5px;
        .form-item {
          height: 42px;
          &.form-ml {
            margin-left: 10px;
          }
          &.form-mr {
            margin-right: 10px;
          }
        }
        .submit-btn {
          margin-right: 15px;
          cursor: pointer;
        }
      }
      .cm-cell {
        height: 68px;
        // padding-left: 5px;
        .form-item {
          height: 42px;
        }
        .submit-btn {
          margin-right: 15px;
        }
      }
      .hm-cell {
        width: auto;
        .form-txt {
          margin-right: 10px;
        }
        .submit-btn {
          margin-right: 15px;
          margin-left: 15px;
        }
      }
      .submit-btn {
        height: 42px;
      }
    }
  }
  .submit-btn {
    width: 160px;
    height: 50px;
    margin: 0 auto;
    background: #2871ff;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}

.move-top {
  animation: option-top 0.5s forwards ease-in-out;
}
.move-hide {
  animation: option-top-hide 0.5s forwards ease-in-out;
}

@media (max-width: 768px) {
  .banner {
    display: flex;
    flex-direction: column;
    padding: 75px 0;
    width: auto;

    &-l {
      padding-top: 0px;
      padding-bottom: 0px;
      .title {
        padding-top: 137px;
        padding-bottom: 16px;
        font-size: 40px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        text-align: center;
        line-height: 55px;
      }
      .sub-title {
        text-align: center;
        padding-top: 16px;
        font-size: 22px;
        font-family: AlibabaPuHuiTiL;
        color: #000000;
        line-height: 36px;
        font-family: sans-serif;
        font-weight: lighter;
        line-height: 36px;
        margin: 0 auto;
      }
      .btn-gp {
        text-align: center;
      }
      .btn {
        margin-top: 30px;
        padding: 4px 10px;
        font-size: 22px;
        text-align: center;
        font-family: AlibabaPuHuiTiR;
      }
      .banner-btn-icon {
        font-size: 30px;
      }
    }
    &-r {
      min-height: 460px;
      margin-top: 35px;
      .banner-r-shadow-1 {
        width: 444px;
        bottom: -36px;
      }
      .banner-r-shadow-2 {
        right: -31px;
        bottom: 67px;
      }
      .banner-r-shadow-3 {
        left: -34px;
        bottom: 62px;
      }
      // &-plug {

      //   &-r {
      //     // font-size: 150px;
      //   }
      // }
      &-main {
        width: 513px;
        height: auto;
      }
      &-plug-r {
        width: 150px;
      }
      &-l {
        width: 150px;
      }
    }
  }
}

@keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
