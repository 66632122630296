<template>
  <div class="about">
    <Navgation bgColor="#060B4C" />
    <div class="about-container">
      <section class="banner">
        <div class="banner-content">
          <div class="banner-content-title">关于我们</div>
          <div class="banner-content-label">
            以客户为中心，以创新提升产品力，帮助创业者降低工商注册，记账报税等业务成本
          </div>
        </div>
      </section>
      <section
        class="story"
        :class="{
          'pre-hide-300': true,
          'move-bottom-300': isShowStory,
        }"
      >
        <div class="story-content">
          <div class="story-content-card">
            <img src="@/assets/images/about/about-story-bg.png" alt="" />
            <div class="story-content-card-text">
              <div class="story-content-card-text-title">创业故事</div>
              <div class="story-content-card-text-line"></div>
              <div class="story-content-card-text-label">
                <p>
                  我们深耕代理记账行业多年，清晰地意识到，国家政策催生了企业工商财税服务这一高频而又刚需的需求。可目前代理记账服务混乱，服务流程不透明，项目收费标准不统一，产品同质化严重，小微企业主花了钱也很难得到很好的服务。
                </p>
                <p>
                  其实代理记账专业性要求不高，工作简单，大量重复劳动，刚毕业的学生借助代账软件也能完成记账报税。说白了代账会计就是把财务数据转化为税务系统能够读懂的语言，我们就在想能不能做出款产品，可以让小微企业主在软件上完成工商注册和记账报税，这样可以大大降低创业的成本。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="company-introduction">
        <div
          class="company-introduction-content"
          :class="{
            'pre-hide-100': true,
            'move-bottom-100': isShowCompanyIntroduction,
          }"
        >
          <img src="@/assets/images/about/company-introduction-bg.png" alt="" />
          <div class="company-introduction-text">
            <div class="company-introduction-text-title">公司介绍</div>
            <div class="company-introduction-text-info">
              <p>
                海南十角兽网络科技有限公司是一家运用移动互联网、机器人流程自动化、人工智能、大数据等最新技术，结合资深财税行业经验，为企业提供便捷、高效、诚挚服务的工商财税数字化服务平台。公司致力于为全国几千万小微企业和亿级个体工商户，提供精准化、场景化、数字化的工商财税服务新体验。
              </p>
              <p>
                公司成立于2021年，总部位于广州，在浙江、上海、山东、江苏等地设有分部，业务范围已经覆盖全国36个税区，已经为超过10万家企业用户提供专业优质的工商财税解决方案。未来，十角兽将基于行业领先的技术研发能力和体系化标准化的客户服务能力，帮助更多的小微企业以更低的成本享受更优质的服务，共同进入工商财税服务数字化新时代。
              </p>
            </div>
            <div class="company-introduction-text-data">
              <div class="company-introduction-text-data-item">
                <div class="company-introduction-text-data-item-right">
                  <div class="company-introduction-text-data-item-right-num">
                    <span>36</span>个
                  </div>
                  <div class="company-introduction-text-data-item-right-label">
                    覆盖税区数量
                  </div>
                </div>
              </div>
              <div class="company-introduction-text-data-item">
                <div class="company-introduction-text-data-item-right">
                  <div class="company-introduction-text-data-item-right-num">
                    <span>100000</span>+
                  </div>
                  <div class="company-introduction-text-data-item-right-label">
                    服务企业数量
                  </div>
                </div>
              </div>
              <div class="company-introduction-text-data-item">
                <div class="company-introduction-text-data-item-right">
                  <div class="company-introduction-text-data-item-right-num">
                    <span>2000</span>元
                  </div>
                  <div class="company-introduction-text-data-item-right-label">
                    每年至少为企业节省
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="qualification"
        :class="{
          'pre-hide-100': true,
          'move-bottom-100-2': isShowQualification,
        }"
      >
        <div class="qualification-content">
          <div class="qualification-title">荣誉资质</div>
          <div class="qualification-imgs">
            <img src="@/assets/images/about/qualification-img1.png" alt="" />
            <img src="@/assets/images/about/qualification-img2.png" alt="" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import {pageSeoInfo} from '@/config'
export default {
  name: 'about',
  components: { Navgation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      isShowStory: false,
      isShowCompanyIntroduction: false,
      isShowQualification: false,
    }
  },
  mounted() {
    this.isShowStory = true
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 1300) {
          this.isShowQualification = true
        } else if (scrollTop >= 600) {
          this.isShowCompanyIntroduction = true
        }
      } else {
        if (scrollTop >= 1550) {
          this.isShowQualification = true
        } else if (scrollTop >= 850) {
          this.isShowCompanyIntroduction = true
        }
      }
    },
  },
  metaInfo: {
    title: pageSeoInfo.home.title,
    meta: [{
      name: 'keywords',
      content: pageSeoInfo.home.keywords
    },
    {
      name: 'description',
      content: pageSeoInfo.home.description
    }]
  },
}
</script>
<style lang="less" scoped>
.about-container {
  margin-top: @navHeight;
  .banner {
    height: 440px;
    background: url('../../assets/images/about/about-bg.png') no-repeat;
    background-size: 100% 440px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        margin-top: 144px;
        font-size: 54px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 75px;
        background: linear-gradient(
          270deg,
          #51ffc4 0%,
          #24eaff 40%,
          #1464ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-label {
        margin-top: 12px;
        // width: 552px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
  .story {
    height: 788px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-card {
        margin-top: 204px;
        width: 1024px;
        height: 444px;
        background: #ffffff;
        box-shadow: 0px 0px 40px 0px rgba(171, 190, 214, 0.38);
        display: flex;
        position: relative;
        img {
          width: 360px;
          height: 444px;
          margin-right: 106px;
        }
        &-text {
          padding-right: 44px;
          box-sizing: border-box;
          &-title {
            margin-top: 60px;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #060b4c;
            line-height: 45px;
          }
          &-line {
            width: 32px;
            height: 4px;
            background: #2871ff;
            border-radius: 2px;
            margin: 14px 0px 16px 0px;
          }
          &-label {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(6, 11, 76, 0.65);
            line-height: 30px;
          }
        }
        &::before {
          content: '';
          display: block;
          width: 216px;
          height: 216px;
          background: #2871ff;
          position: absolute;
          top: -60px;
          left: -60px;
          z-index: -1;
        }
      }
    }
  }
  .company-introduction {
    height: 728px;
    background: linear-gradient(
      125deg,
      #d3e4f9 0%,
      #f6f9fe 100%,
      #f6f9fe 100%,
      #f6f9fe 100%
    );
    position: relative;
    z-index: 2;
    overflow: hidden;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      position: relative;
      img {
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 714px;
        height: 674px;
      }
      .company-introduction-text {
        padding-top: 82px;
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        &-title {
          font-size: 48px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #102a55;
          line-height: 67px;
        }
        &-info {
          width: 600px;
          p {
            margin-top: 26px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(6, 11, 76, 0.65);
            line-height: 30px;
          }
        }
        &-data {
          margin-top: 100px;
          display: flex;

          &-item {
            margin-right: 72px;
            display: flex;
            align-items: center;
            &::before {
              content: '';
              display: block;
              height: 52px;
              border: 2px solid #2871ff;
            }
            &-right {
              margin-left: 20px;
              &-num {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #102a55;
                span {
                  font-size: 32px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                }
              }
              &-label {
                margin-top: 6px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.5);
                line-height: 22px;
                letter-spacing: 2px;
              }
            }
          }
        }
      }
    }
  }
  .qualification {
    height: 660px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .qualification-title {
        margin-top: 90px;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        line-height: 67px;
      }
      .qualification-imgs {
        margin-top: 64px;
        img:nth-of-type(1) {
          width: 226px;
          height: 306px;
        }
        img:nth-of-type(2) {
          margin-left: 90px;
          width: 430px;
          height: 306px;
        }
      }
    }
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-2 {
    animation: move-bottom-100-2 1s 0.2s forwards ease-in-out;
  }
}
@keyframes move-bottom-300 {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes move-bottom-100 {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes move-bottom-100-2 {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
