<template>
  <div>
      <div id="downloadButton" style="color: rgba(0,0,0,.5); font-size: 20px; text-align: center; cursor: pointer; margin-top: 30%;">点击下载或打开十角兽APP</div>

  </div>
</template>

<script>
import { pageSeoInfo } from '@/config'

export default {
  name: 'download',
  filters: {},
  components: { },
  props: {},
  data() {
    return {
    }
  },
  metaInfo: {
    title: '十角兽APP',
    meta: [{
      name: 'keywords',
      content: '十角兽APP'
    },
    {
      name: 'description',
      content: '十角兽APP'
    }]
  },
  computed: {},
  watch: {},
  mounted() {
    this.openinstall()
  },
  created() {},
  methods: {
    openinstall(){
      var data = OpenInstall.parseUrlParams();//js中提供的api，解析当前网页url中的查询参数并对data进行赋值
      new OpenInstall({//初始化方法，与openinstall服务器交互，应尽早调用
        appKey : "u0qsq4",//appKey为openinstall为应用分配的唯一id（必须传入）
        onready : function() {//初始化成功回调方法。当初始化完成后，会自动进入
          this.schemeWakeup();//尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
          var m = this, button = document.getElementById("downloadButton");//为button对象绑定对应id的元素
          button.onclick = function() {//对应button的点击事件
            m.wakeupOrInstall();//此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
            return false;
          }
          // 模拟点击
          setTimeout(() => {
            document.querySelector('#downloadButton').click()
          }, 800);
        }
      }, data);//初始化时传入data，作为一键拉起/App传参安装时候的参数
    }
  },
}
</script>

<style scoped lang="less">
.container {
  padding-top: 120px;
}
</style>
